<template>
  <div class="app-container">
    <el-form :model="form" :rules="rules" ref="form" label-width="120px">
      <el-form-item label="标题/名称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="产品简介" prop="synopsis">
        <el-input
          v-model="form.synopsis"
          placeholder="请输入简单介绍"
          type="textarea"
          :rows="4"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="类型" prop="product_type_id">
        <el-select
          v-model="form.product_type_id"
          placeholder="请选择类型"
          clearable
          style="width: 150px"
          class="filter-item"
        >
          <el-option
            v-for="(item, key) in typelist"
            :key="key"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>-->
      <el-form-item label="封面(140*140)" prop="cover">
        <upload-one
          v-model="form.cover"
          style="width: 70px; height: 70px"
        ></upload-one>
      </el-form-item>
      <el-form-item label="图片(750*750)" prop="images">
        <UploadList
          v-model="form.images"
          width="188px"
          height="188px"
        ></UploadList>
      </el-form-item>
      <el-form-item label="参考价格" prop="reference_price">
        <el-input
          v-model="form.reference_price"
          style="width: 200px"
          onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
          onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="价格" prop="price">
        <el-input
          v-model="form.price"
          style="width: 200px"
          onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
          onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
        ></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="库存" prop="stock">
        <el-input
          type="number"
          v-model="form.stock"
          style="width: 200px"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="规格" prop="form.product_specs">
        <template>
          <el-tag
            :key="key"
            v-for="(tag, key) in form.product_specs"
            closable
            :disable-transitions="false"
            style="margin-right: 10px"
            @close="handleClose(key)"
          >
            {{ tag.name }}
          </el-tag>
          <el-button size="small" @click="addTags">添加</el-button>
        </template>
      </el-form-item>
      <el-form-item v-if="tableData.length > 0">
        <template>
          <el-table :data="tableData" border style="width: 50%">
            <el-table-column prop="product_specs" label="sku组合" width="180">
            </el-table-column>
            <!-- <el-table-column prop="price" label="售价" min-width="200">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.price"
                  style="width: 200px"
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                  onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                ></el-input>
              </template>
            </el-table-column> -->
            <el-table-column prop="price" label="库存" min-width="200">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.stock"
                  style="width: 200px"
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                  onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="库存价格" min-width="200" >
              <template slot-scope="scope">
                <el-input
                  @blur="priceblur(scope.row.price)"
                  v-model="scope.row.price"
                  min="0.01"
                  style="width: 200px"
                  onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                  onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-form-item>
      <el-form-item label="详情介绍" prop="introduce">
        <tinymce
          :height="400"
          v-model="form.introduce"
          :value="form.introduce"
        />
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.go(-1)">返回</el-button>
        <el-button type="primary" :loading="btnLoading" @click="saveData">
          提交
        </el-button>
      </el-form-item>
    </el-form>

    <el-dialog :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="规格类型" prop="product_id">
          <el-select
            v-model="form.product_id"
            placeholder="请选择类型"
            clearable
            style="width: 150px"
            class="filter-item"
            @change="selectType"
          >
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="规格名称" prop="remark">
          <el-tag
            :key="key"
            v-for="(tag, key) in product_list"
            closable
            :disable-transitions="false"
            @close="handleType(key)"
            style="margin-right: 10px"
          >
            {{ tag.name }}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            autofocus="autofocus"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput"
            >添加</el-button
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="addGuiGe" :loading="btnLoading">
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Tinymce from "@/components/Tinymce";
import UploadList from "@/components/UploadList/index.vue";
import request from "@/utils/request";
import { descartes } from "@/utils/tool";
export default {
  components: {
    Tinymce,
    UploadList,
  },
  data() {
    return {
      typeList: [
        { id: 1, name: "颜色" },
        { id: 2, name: "尺寸" },
        { id: 3, name: "尺码" },
        { id: 4, name: "容量" },
        { id: 5, name: "款式" },
        { id: 6, name: "净含量" },
        { id: 7, name: "口味" },
        { id: 8, name: "套餐类型" },
        { id: 9, name: "版本类型" },
        { id: 11, name: "长度" },
        { id: 12, name: "重量" },
      ],
      btnLoading: false,
      dialogFormVisible: false,
      tableData: [],
      dynamicTags: [],
      product_list: [],
      form: {
        id: "",
        // type: null,
        product_type_id: "", //类型
        name: "",
        cover: "",
        synopsis: "", //产品简介
        images: [],
        reference_price: "",
        stock: "", //库存
        stockprice:"",//库存价格
        introduce: "", //介绍
        tags: [
          // {
          //   id: "",
          //   type: "", //类型：1.颜色 2.容量 3.尺寸
          //   name: "",
          // },
        ],
        product_specs: [],
        tags_combination: [
          // {
          //   id: "",
          //   tags: "", //产品规格 ，隔开
          //   snap: "", //显示名称
          //   price: "",
          //   stock: "", //stock
          // },
        ],
      },
      rules: {
        name: [
          { required: true, message: "标题/名称不能为空", trigger: "change" },
        ],
        synopsis: [
          { required: true, message: "产品简介不能为空", trigger: "change" },
        ],
        stock: [{ required: true, message: "库存不能为空", trigger: "blur" }],
        product_type_id: [
          { required: true, message: "请选择类目", trigger: "change" },
        ],
        cover: [{ required: true, message: "请上传封面", trigger: "blur" }],
        images: [
          {
            required: true,
            message: "请上传图片",
            trigger: "blur",
          },
        ],
        tags: [
          {
            tyep: "array",
            required: true,
            message: "规格不能为空",
            trigger: "change",
          },
        ],
        product_specs: [
          {
            tyep: "array",
            required: true,
            message: "规格不能为空",
            trigger: "change",
          },
        ],
        reference_price: [
          { required: true, message: "参考价格不能为空", trigger: "blur" },
        ],
        price: [{ required: true, message: "价格不能为空", trigger: "blur" }],
        introduce: [
          { required: true, message: "详情介绍不能为空", trigger: "blur" },
        ],
      },
      typelist: [],
      inputValue: "",
      sType: null,
      inputVisible: false,
    };
  },
  created() {
    if (this.$route.query.id != "undefined") {
      this.getDetail(this.$route.query.id);
    }
  },
  mounted() {},
  methods: {
    priceblur(price){
        if(price==0){
            return this.$message.error("价格不能为空");
        }
    },
    addTags() {
      this.dialogFormVisible = true;
      this.sType = null;
      this.form.type = null;
      this.form.product_id=null
      this.$forceUpdate(); //强制更新
    },
    addGuiGe() {
      this.product_list.forEach((item) => {
        this.form.product_specs.push(item);
      });
      this.product_list = [];
      this.dialogFormVisible = false;
      this.setSKU();
    },
    handleType(e) {
      this.form.product_list.splice(e, 1);
    },
    showInput() {
      this.inputValue = "";
      this.inputVisible = true;
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue && this.sType != '') {
        this.product_list.push({ id: "", type: this.sType, name: inputValue });
      }
      console.log(this.product_list);
      this.inputVisible = false;
      this.inputValue = "";
    },
    setSKU() {
      let arrayTwo = Object.values(
        this.form.product_specs.reduce((res, item) => {
          res[item.type]
            ? res[item.type].push(item.name)
            : (res[item.type] = [item.name]);
          return res;
        }, {})
      );
      console.log(arrayTwo,'===============');
      let r = descartes(arrayTwo);
      let newArr1 = [];
      r.map((item) => {
        let obj = {
          id: "",
          product_specs: item.join(","),
          snap: "0",
          // price: this.form.reference_price,
          stock: "0",
          price: "0"
        };
        newArr1.push(obj);
      });
      console.log(newArr1, "==================sku");
      this.form.product_specs_combination = newArr1;
      this.tableData = newArr1;
    },
    getTypeList() {
      request({
        url: "/api/backend/product/typeList",
        method: "get",
        params: { limit: 9999999999 },
      }).then((response) => {
        this.typelist = response.data.data;
      });
    },
    handleClose(e) {
      this.form.product_specs.splice(e, 1);
      this.setSKU();
    },
    selectType(e) {
      console.log(e);
      this.sType = e;
      this.form.product_id=e
      this.$forceUpdate()//强制更新
    },
    getDetail(id) {
      request({
        url: "/api/backend/product/detail",
        method: "get",
        params: {
          id: id,
        },
      }).then((response) => {
        if (response.data) {
          this.form = response.data;
          this.tableData = response.data.product_specs_combination;
          // this.form.value = JSON.parse(this.form.value);
          console.log(this.form);
        }
      });
    },
    saveData() {
      // if (!this.form.product_type_id) {
      //   this.$message.error("类型不能为空！");
      //   return;
      // }
      if (!this.form.name) {
        this.$message.error("标题/名称不能为空！");
        return;
      }
      if (!this.form.cover) {
        this.$message.error("封面不能为空！");
        return;
      }
      if (this.form.images.length == 0) {
        this.$message.error("图片不能为空！");
        return;
      }
    
      if (this.form.product_specs.length == 0) {
        this.$message.error("规格不能为空！");
        return;
      }
        if (this.form.price==0) {
        this.$message.error("参考价格不能为空！");
        return;
      }
      if (!this.form.introduce) {
        this.$message.error("介绍不能为空！");
        return;
      }
      if (!this.form.introduce) {
        this.$message.error("介绍不能为空！");
        return;
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          this.form.tags_combination = this.tableData;
          request({
            url: "/api/backend/product/store",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              setTimeout(() => {
                this.$router.go(-1); //返回上一层
              }, 500);
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-input {
  width: 300px;
}
.input-new-tag {
  width: 150px;
}
</style>